import React, {ComponentProps} from "react";
import NetworkIcon from "@/Components/NetworkIcon";
import clsx from "clsx";

export default function NetworkIconsStack(
    {
        supportedChains,
        className,
        children,
        iconClassName = 'bg-stone-200/60 p-2 rounded-full ring ring-white',
        ...props
    }: ComponentProps<'div'> & { supportedChains: string[], iconClassName?: string }
) {
    return (
        <div className={clsx(className, "isolate flex -space-x-0.5 overflow-hidden")} {...props}>
            {children}
            {supportedChains.map((chain, index) => (
                <div key={chain} className={iconClassName} style={{zIndex: supportedChains.length - index}}>
                    <NetworkIcon network={chain} size={25}/>
                </div>
            ))}
        </div>
    );
}
