import React, {ComponentProps, useEffect, useRef} from "react";
import {motion, useAnimation} from "framer-motion";

export default function Motion({className, children, ...props}: ComponentProps<'div'>) {
    const controls = useAnimation();
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    controls.start("visible");
                }
            },
            {
                threshold: 0.1,
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [controls]);

    const cardVariants = {
        hidden: {opacity: 0, y: 20},
        visible: {opacity: 1, y: 0, transition: {duration: 0.5}},
    };

    return (
        <motion.div ref={ref} initial="hidden" animate={controls} variants={cardVariants} className={className}>
            {children}
        </motion.div>
    );
};
