import React, {useContext} from "react";
import Section from "@/Components/Section";
import {ContactDialogContext} from "@/Globals/ContactDialog";
import Button, {ButtonScale} from "@/Components/Button";

export default function Contact() {

    const {openDialog: openContactDialog} = useContext(ContactDialogContext);

    return (

        <Section className="relative mt-8 sm:mt-12 mb-12 flex flex-col sm:flex-row items-center text-center sm:text-left sm:justify-between">
            <div className="mb-4">
                <h1 className="font-display font-semibold text-xl mb-2">Next steps?</h1>
                <p className="text-lg">Book a live demo, request quote, or discuss partnerships</p>
            </div>

            <Button scale={ButtonScale.Large} onClick={openContactDialog}>
                Contact Us
            </Button>
        </Section>

    )
}
