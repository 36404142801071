import React, {HTMLAttributes, PropsWithChildren} from 'react';
import BaseLayout from "@/Layouts/BaseLayout";
import Container from "@/Components/Container";
import Header from "@/Components/Header";
import Footer from "@/Components/Footer";
import clsx from "clsx";

import '../../css/guest.css';

interface MarketingProps extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
    withHeader?: boolean;
}

export default function MarketingLayout({className = '', children, withHeader = true}: MarketingProps) {
    return (
        <BaseLayout>

            <div className={clsx("flex flex-col min-h-screen", className)}>

                {withHeader && <Container expanded={true} className="w-full">
                    <Header/>
                </Container>}

                <div className="grow">
                    {children}
                </div>

                <Container expanded={true} >
                    <div>
                        <Footer/>
                    </div>
                </Container>
            </div>

        </BaseLayout>
    );
}
